<template>
  <Page :title="$t('privacity.title')" :classes="['page--static', 'page--privacity']">
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui ut ornare lectus sit amet est placerat in. Semper risus in hendrerit gravida. Leo a diam sollicitudin tempor id eu. At consectetur lorem donec massa sapien faucibus et. Non pulvinar neque laoreet suspendisse. Sed libero enim sed faucibus turpis. Aliquam etiam erat velit scelerisque in dictum. Ac orci phasellus egestas tellus rutrum. Vulputate eu scelerisque felis imperdiet proin fermentum leo. Massa tempor nec feugiat nisl pretium fusce id velit ut. Semper eget duis at tellus at urna. Placerat orci nulla pellentesque dignissim enim sit amet. Amet consectetur adipiscing elit ut aliquam purus. Urna porttitor rhoncus dolor purus non enim praesent elementum facilisis. Orci dapibus ultrices in iaculis nunc sed augue. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum.</p>

    <p>Felis bibendum ut tristique et egestas. Scelerisque purus semper eget duis at. Blandit massa enim nec dui. In hac habitasse platea dictumst quisque sagittis purus sit. Habitant morbi tristique senectus et netus et malesuada fames. Egestas sed tempus urna et pharetra. Facilisi morbi tempus iaculis urna id volutpat lacus laoreet non. Sagittis eu volutpat odio facilisis. Mattis aliquam faucibus purus in. Quis risus sed vulputate odio. Semper viverra nam libero justo laoreet sit amet cursus sit. Purus non enim praesent elementum facilisis leo vel fringilla est. Vitae tempus quam pellentesque nec nam aliquam sem et. Aliquet nec ullamcorper sit amet risus nullam eget felis. Ac tortor dignissim convallis aenean. Risus nec feugiat in fermentum posuere urna nec tincidunt. Eu lobortis elementum nibh tellus molestie. Leo vel orci porta non pulvinar neque laoreet suspendisse.</p>

    <p>Nam libero justo laoreet sit amet. Eros in cursus turpis massa. Lacinia at quis risus sed vulputate odio ut. Et sollicitudin ac orci phasellus egestas tellus rutrum tellus pellentesque. Leo integer malesuada nunc vel. Sit amet venenatis urna cursus eget nunc. Et tortor at risus viverra adipiscing at in tellus integer. At lectus urna duis convallis convallis tellus id interdum. Hac habitasse platea dictumst quisque sagittis purus sit. Ac turpis egestas sed tempus urna et. Sed risus pretium quam vulputate dignissim. Massa vitae tortor condimentum lacinia quis vel eros donec. Posuere urna nec tincidunt praesent semper feugiat.</p>

    <p>Pharetra convallis posuere morbi leo. Blandit massa enim nec dui nunc mattis enim ut. Consequat ac felis donec et odio pellentesque diam. Urna neque viverra justo nec ultrices dui sapien. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat. Facilisi nullam vehicula ipsum a arcu cursus vitae. Sit amet porttitor eget dolor morbi non arcu risus. Tempus imperdiet nulla malesuada pellentesque elit eget gravida cum. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Consectetur adipiscing elit duis tristique sollicitudin nibh sit amet. Diam quam nulla porttitor massa. Nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Pulvinar etiam non quam lacus suspendisse faucibus. Nibh sed pulvinar proin gravida hendrerit lectus. Ac auctor augue mauris augue neque gravida. Molestie a iaculis at erat pellentesque adipiscing commodo elit at. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet consectetur.</p>

    <p>Lacus luctus accumsan tortor posuere ac ut consequat semper viverra. Dictum varius duis at consectetur lorem donec massa. Imperdiet sed euismod nisi porta lorem mollis aliquam ut. Hac habitasse platea dictumst vestibulum rhoncus. In aliquam sem fringilla ut morbi tincidunt augue. Non blandit massa enim nec dui nunc mattis. Fames ac turpis egestas sed tempus urna et pharetra pharetra. Velit euismod in pellentesque massa placerat duis ultricies lacus sed. Habitant morbi tristique senectus et netus et. Egestas erat imperdiet sed euismod nisi porta. Orci porta non pulvinar neque. Pretium nibh ipsum consequat nisl. Ultricies mi quis hendrerit dolor magna eget est lorem ipsum. Nunc pulvinar sapien et ligula. Aliquet nibh praesent tristique magna sit amet purus. Tempus quam pellentesque nec nam aliquam sem et tortor. Quis ipsum suspendisse ultrices gravida dictum fusce ut. Vitae ultricies leo integer malesuada nunc vel risus. Adipiscing diam donec adipiscing tristique risus nec.</p>
  </Page>
</template>

<script>
export default {
  name: 'PrivacityPage',
};
</script>
